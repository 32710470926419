import React from "react"
import CartPage from "../../components/CartPage/CartPage"
import Utils from "../../utils/node.utils"
import { StaticQuery, graphql } from "gatsby"

const CartIndexPage = ({ location }) => {
  Utils.NavigateToBrowserLanguage("es", "/cart", location.state)
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        const contentMap = Utils.ContentListToMap(data.cartContent.item.content)
        return (
          <CartPage
            title={contentMap.seo_title.value}
            description={contentMap.seo_description.value}
            breadCrumbs={["menu_home", "menu_cart"]}
            location={location}
            originPage="cart"
            language="es"
            contentMap={contentMap}
            commonContentMap={commonContentMap}
            keywords={Utils.GetKeywordsFromTags(data.cartContent.item.tags)}
          />
        )
      }}
    />
  )
}

export default CartIndexPage

const pageQuery = graphql`
  {
    cartContent: cartContentEses {
      item {
        key
        tags {
          content {
            key
            value
          }
        }
        content {
          key
          value
        }
      }
    }

    commonContent: commonContentEses {
      item {
        key
        content {
          key
          value
        }
      }
    }
  }
`
