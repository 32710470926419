import React, { Component } from "react"
import Layout from "../Layout/Layout/Layout"
import SEO from "../SEO/SEO"
import CartView from "../Cart/CartView"
import Header from "../Shop/Header/Header"
import Utils from "../../utils/node.utils"

export default class CartPage extends Component {
  state = {}

  componentDidMount() {
    const checkoutLink = Utils.OrderInProgress()

    if (checkoutLink) {
      window.location.replace(checkoutLink)
      return
    }
  }

  render() {
    const {
      title,
      description,
      location,
      originPage,
      language,
      contentMap,
      commonContentMap,
      breadCrumbs,
      keywords,
    } = this.props

    return (
      <Layout
        origin={location.origin}
        originPage={originPage}
        language={language}
        commonContentMap={commonContentMap}
      >
        <SEO
          title={title}
          language={language}
          keywords={keywords}
          description={description}
          selfLink={originPage}
        />
        <Header breadCrumbs={breadCrumbs} commonContentMap={commonContentMap} />
        <CartView
          language={language}
          commonContentMap={commonContentMap}
          contentMap={contentMap}
        />
      </Layout>
    )
  }
}
